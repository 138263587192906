import { useContext, useState } from "react";
import axios from "axios";
import AppContext from "../../../AppContext/AppContext";
import { CONTACTS_API } from "../../../Utilities/APIs";
import Navbar from "../../Partials/Navbar/Navbar";

import "./Contact.css";
import { toast } from "react-toastify";

function Contact() {
  const { services } = useContext(AppContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  // const [service, setService] = useState('');

  // ... (other state variables)

  const [selectedServices, setSelectedServices] = useState([]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      firstName,
      lastName,
      email,
      phone,
      company,
      message,
      service: selectedServices,
    };

    //const profileData = await axios.post(api, item object)
    const profileData = await axios.post(CONTACTS_API, itemData);

    if (profileData.status === 200) {
      toast.success("Thank you for submitting the form.", {
        theme: "dark",
      });
      //set message or clear input field
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setMessage("");
      setSelectedServices([]);
    }
  }

  return (
    <>
      <Navbar />
      <section className="contact_page">
        <div className="container_fluid container">
          <h1>Get to know each other.</h1>
          <div className="form_wrapper">
            <form onSubmit={handleSubmit}>
              <div className="grid_2">
                <div className="input_group">
                  <label className="label">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="input_group">
                  <label className="label">Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="input_group">
                <label className="label">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input_group">
                <label className="label">Phone</label>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="input_group">
                <label className="label">Company Name</label>
                <input
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>
              <div className="checkboxs_wrapper">
                <label className="label">What to Discuss</label>

                <div className="grid_2">
                  {services &&
                    services
                    .filter(service => service.slug !== 'uddinfoundation')
                    .map((service) => (
                      <div className="input_group" key={service._id}>
                        <input
                          type="checkbox"
                          id={service._id}
                          value={service._id}
                          checked={selectedServices.includes(service._id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedServices([
                                ...selectedServices,
                                service._id,
                              ]);
                            } else {
                              setSelectedServices(
                                selectedServices.filter(
                                  (id) => id !== service._id
                                )
                              );
                            }
                          }}
                        />
                        <label htmlFor={service._id}>
                          <div className="outer">
                            <div className="inner"></div>
                          </div>
                          <span>{service && service?.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="input_group">
                <label className="label">Message</label>
                <textarea
                  rows="6"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="submit_btn_wrap">
                <button type="submit"> <span>Send Message</span> </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
