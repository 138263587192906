import { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IMAGE_URL, SERVICE_TESTIMONIALS_API } from '../../../Utilities/APIs';
// import imgLogo from '../../../Assets/Images/ngo-logo.svg'

import 'swiper/css';
import 'swiper/css/pagination';
import './Testimonial2.css'

function Testimonial2({isBlackBg, slug}) {

    const [testimonials, setTestimonials] = useState(null);

    useEffect(() => {
        async function getTestimonials() {
            const { data } = await axios.get(SERVICE_TESTIMONIALS_API);
            setTestimonials(data?.filter(tstm => tstm.service?.slug === slug));
        }
        getTestimonials()
    }, [slug])


    return (
        <section className={`testimonial2_sc ${isBlackBg && 'black'} py_10`}>
            <div className="container_fluid container">
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    navigation={true}
                >
                    {testimonials &&
                    testimonials?.map(testimonial => (
                        <SwiperSlide key={testimonial._id}>
                            <div className="grid_container">
                                <div className="grid_item logo_item">
                                    <img src={testimonial && IMAGE_URL + testimonial?.image} alt="icon" className='org_logo' />
                                </div>
                                <div className="grid_item">
                                    <p>{testimonial && testimonial?.description}</p>
                                    <h5>{testimonial && testimonial?.name}</h5>
                                    <span>{testimonial && testimonial?.designation}</span>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    )
}

export default Testimonial2