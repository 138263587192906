import { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ARTICLES_API } from '../../../Utilities/APIs';
import MediaCard from '../MediaCard/MediaCard';

import 'swiper/css';
import 'swiper/css/navigation';
import './PressReleaseSc.css';

function PressReleaseSc({ slug, scTtitle, scSubTitle }) {

    const [articles, setArticles] = useState(null);

    useEffect(() => {
        async function getArticless() {
            const { data } = await axios.get(ARTICLES_API + 'getAllArticlesForConsumer');
            setArticles(data?.filter(artl => artl.service?.slug === slug));
        }

        getArticless()
    }, [slug])


    return (
        <section className="press_release_sc white_pagination py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper with_flex right_bottom mb_5">
                    <div className="flex_item">
                        <h4 className="title_24">{scSubTitle}</h4>
                        <h1 className="title_60">{scTtitle}</h1>
                    </div>
                    <div className="flex_item">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={{
                        prevEl: `.press_release_sc .swiper-button-prev`,
                        nextEl: `.press_release_sc .swiper-button-next`
                    }}
                    pagination={{
                        el: '.press_release_sc .swiper-pagination',
                        clickable: true,
                    }}

                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }}
                >
                    {articles &&
                        articles?.map(article => (
                            <SwiperSlide key={article._id}>
                                <MediaCard
                                    img={article?.image}
                                    date={article?.publishDate}
                                    tag={article?.tag}
                                    name={article?.name}
                                    link={article?.link}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            <div className="swiper-pagination"></div>
        </section>
    )
}

export default PressReleaseSc