import { useContext } from 'react';
import AppContext from '../../../../AppContext/AppContext';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ServiceCard from './ServiceCard/ServiceCard';

import 'swiper/css';
import 'swiper/css/pagination';
import './Services.css'

function Services() {

    const { secTitles, services } = useContext(AppContext)


    return (
        <section className="my_10 services_sc">
            <div className="container_fluid container">
                <div className="sch_wrapper with_flex mb_5">
                    <div className="flex_item">
                        <h4 className="title_24 fc_white">{secTitles?.serviceSectionSubtitle}</h4>
                        <h1 className="title_60 fc_white">{secTitles?.serviceSectiontitle}</h1>
                    </div>
                    <div className="flex_item"></div>
                </div>
            </div>

            <div className="service_slider_wrapper">
                <Swiper
                    speed={2000}
                    rewind={false}
                    spaceBetween={13}
                    slidesPerView={1.25}
                    // autoplay={{
                    //     delay: 2000
                    // }}
                    autoplay={false}
                    pagination={{
                        el: '.services_sc .swiper-pagination',
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    breakpoints={{
                        768: {
                            slidesPerView: 2.25,
                        },
                        1024: {
                            slidesPerView: 4.25,
                        },
                    }}
                >
                    {services?.map(service => (
                        <SwiperSlide key={service._id}>
                            <ServiceCard service={service} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="swiper-pagination"></div>
        </section>
    )
}

export default Services