import './ImpactCard.css'

function ImpactCard({ number, title, desc }) {
    return (
        <div className='impact_card'>
            <div className="number">
                <h1>{number}</h1>
                {/* <span>+</span> */}
            </div>
            {title &&
                <h5>{title}</h5>
            }
            {desc &&
                <p>{desc}</p>
            }
        </div>
    )
}

export default ImpactCard