// import bgImgg from '../../../../Assets/Images/svevice-bg.png'
import { IMAGE_URL } from '../../../../Utilities/APIs'

import './Banner.css'

function Banner({ bgImg, name, precedence }) {

    return (
        <section className="service_banner" style={{ backgroundImage: `url('${IMAGE_URL + bgImg}')` }}>
            <div className="container_fluid container">
                <div className="banner_inner">
                    <h1>{name}</h1>
                    <h1>0{precedence}</h1>
                </div>
            </div>
        </section>
    )
}

export default Banner