import { useEffect, useState } from 'react';
import axios from 'axios';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ExhibitionCard from './ExhibitionCard/ExhibitionCard'
import { EXHIBITIONS_API } from '../../../../Utilities/APIs';

import 'swiper/css';
import 'swiper/css/pagination';
import './Exhibitions.css'

function Exhibitions({slug, scTtitle}) {

    const [exhibitions, setExhibitions] = useState(null);

    useEffect(() => {
        async function getExhibitions() {
            const { data } = await axios.get(EXHIBITIONS_API);
            setExhibitions(data?.filter(blg => blg.service?.slug === slug));
        }
        getExhibitions()
    }, [slug])


    return (
        <section className="exhibitions py_10 my_10">
            <div className="container_fluid container">
                <div className="sch_wrapper mb_7">
                    <h2 className="title_48 center">{scTtitle}</h2>
                </div>
                <div className="exhibition_slider_wrapper">
                    <Swiper
                        modules={[Pagination]}
                        autoPlay={true}
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{
                            el: '.exhibitions .swiper-pagination',
                            clickable: true,
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 30
                            }
                        }}
                    >
                        {exhibitions &&
                            exhibitions?.map(exhibition => (
                                <SwiperSlide key={exhibition._id}>
                                    <ExhibitionCard exhibition={exhibition} />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    )
}

export default Exhibitions