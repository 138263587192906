import { useEffect, useState } from 'react';
import axios from 'axios';
import { SUB_SERVICES_API } from '../../../../Utilities/APIs';
import Number from '../Number/Number'

import './SvrOverview.css'

function SvrOverview({ slug, scTtitle }) {

    const [svrOverviews, setSvrOverviews] = useState(null);

    useEffect(() => {
        async function getSvrOverviews() {
            const { data } = await axios.get(SUB_SERVICES_API);
            setSvrOverviews(data?.filter(srvo => srvo.service?.slug === slug));
        }
        getSvrOverviews()
    }, [slug])


    return (
        <section className="srv_overview py_10">
            <div className="container_fluid container">
                <div className="grid_1_3">
                    <div className="grid_item">
                        <h2 className="title_48">{scTtitle}</h2>
                    </div>
                    <div className="grid_item">
                        {svrOverviews &&
                            svrOverviews?.map(svrOverviews => (
                                <div className="svr_ovrv_card" key={svrOverviews._id}>
                                    <Number text={svrOverviews && svrOverviews?.precedence} />
                                    <h4>{svrOverviews && svrOverviews?.name}</h4>
                                    <p>{svrOverviews && svrOverviews?.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SvrOverview