import { RiCloseLine } from "react-icons/ri"

import './Iframe.css'

function Iframe({videoLink, closeFunc}) {

    return (
        <div className="video_iframe_wrapper">
            <button type='button' className='modal_close' onClick={closeFunc}>
                <RiCloseLine />
            </button>
            <iframe src={`https://www.youtube.com/embed/${videoLink}`}
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
            </iframe>
        </div>
    )
}
export default Iframe