import { Link } from 'react-router-dom'

import arrowRound from '../../../../../Assets/Icons/arrow-round-right-black.svg'

import './ServiceCard.css'

function ServiceCard({service}) {

    if (service?.slug === 'uddinfoundation') {
        return null;
    }
    
    return (
        <div className={`service_card ${service.textColor}`} style={{backgroundColor: `${service.colorCode}`}}>
            <h1>0{service.precedence}</h1>
            <div className='content'>
                <div className='head'>
                    <h3>{service.name}</h3>
                    <Link to={"/service/" + service?.slug}>
                        <img src={arrowRound} alt="icon" />
                    </Link>
                </div>
                {/* <p>{service.description.slice(0,200)}...</p> */}
                <p>{service.shortDescription}</p>
            </div>
        </div>
    )
}

export default ServiceCard