import React from "react";
// import ImageGallery from "./ImageGallery/ImageGallery";
import Navbar from "../../Partials/Navbar/Navbar";
import NMArticles from "./NMArticles/NMArticles";
import NMSuccessStory from "./NMSuccessStory/NMSuccessStory";
import NMReports from "./NMReports/NMReports";

function NewsMedia() {
  return (
    <div>
      <Navbar />
      {/* <ImageGallery /> */}
      <NMArticles />
      <NMSuccessStory />
      <NMReports />
    </div>
  );
}

export default NewsMedia;
