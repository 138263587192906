import { useContext, useEffect, useState } from "react";
// import axios from 'axios'
// import { FOOTER_TEXT_API } from '../../../Utilities/APIs'
import { Link } from "react-router-dom";
import logoImg from "../../../Assets/Images/logo.svg";
import AppContext from "../../../AppContext/AppContext";

import "./Footer.css";
import SocialLinks from "../../Layouts/SocialLinks/SocialLinks";
import { CONTACT_INFOS_API } from "../../../Utilities/APIs";
import axios from "axios";

function Footer() {
  const { services } = useContext(AppContext);

  const [contactInfo, setContactInfo] = useState(null);
  useEffect(() => {
    async function getFooterText() {
      const { data } = await axios.get(CONTACT_INFOS_API);
      setContactInfo(data[0]);
    }
    getFooterText();
  }, []);

  return (
    <div className="footer">
      <div className="footer_top">
        <div className="container_fluid container">
          <div className="footer_top_content">
            <div className="footer_top_content_inner">
              {/* <h1>{footerText && footerText.title}</h1> */}
              <h1>
                Get in touch with <br /> Mostafiz Uddin
              </h1>
              <Link to="/contact" className="contact_arrow">
                <p>Contact</p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.4423 16.6956C22.6656 16.6981 28.5955 16.6619 28.6199 16.6152C28.7041 16.4531 28.3644 15.5224 27.9945 14.9023C27.6364 14.3016 27.4272 14.0844 22.2295 8.91946L16.8362 3.56001L17.7329 2.66332L18.6296 1.76663L23.6445 6.68722C28.7849 11.7309 29.4998 12.4884 30.1803 13.6123C31.7175 16.1513 31.7778 19.5443 30.3316 22.1326C29.7273 23.2141 29.1651 23.8157 23.8473 29.0709L18.6921 34.1653L17.7959 33.2691L16.8996 32.3729L22.013 27.3002C27.7804 21.5786 28.0387 21.2924 28.4287 20.1909C28.5622 19.8141 28.6443 19.4454 28.6113 19.3713C28.5691 19.2767 24.6445 19.2362 15.4282 19.2354L2.3052 19.2343L2.30285 17.9669C2.30158 17.2699 2.30234 16.6977 2.30469 16.6953C2.30697 16.693 8.21894 16.6932 15.4423 16.6956Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.4423 16.6956C22.6656 16.6981 28.5955 16.6619 28.6199 16.6152C28.7041 16.4531 28.3644 15.5224 27.9945 14.9023C27.6364 14.3016 27.4272 14.0844 22.2295 8.91946L16.8362 3.56001L17.7329 2.66332L18.6296 1.76663L23.6445 6.68722C28.7849 11.7309 29.4998 12.4884 30.1803 13.6123C31.7175 16.1513 31.7778 19.5443 30.3316 22.1326C29.7273 23.2141 29.1651 23.8157 23.8473 29.0709L18.6921 34.1653L17.7959 33.2691L16.8996 32.3729L22.013 27.3002C27.7804 21.5786 28.0387 21.2924 28.4287 20.1909C28.5622 19.8141 28.6443 19.4454 28.6113 19.3713C28.5691 19.2767 24.6445 19.2362 15.4282 19.2354L2.3052 19.2343L2.30285 17.9669C2.30158 17.2699 2.30234 16.6977 2.30469 16.6953C2.30697 16.693 8.21894 16.6932 15.4423 16.6956Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_content pt_8 pb_6">
        <div className="container_fluid container">
          <div className="grid_container">
            <div className="grid_item">
              <Link to="/">
                <img src={logoImg} alt="brand" />
              </Link>
              {/* <p>Plot No: 96-100, Sector 3, Karnaphuli, EPZ North Patenga, Chittagong-4204, Bangladesh</p> */}
              <p>{contactInfo?.address}</p>
              <p>
                <a href={`mailto:${contactInfo?.email}`} class="pointer-small">
                  {contactInfo?.email}
                </a>
                <br />

                <a href={`tel:${contactInfo?.phone}`} class="pointer-small">
                  {contactInfo?.phone}
                </a>
              </p>
            </div>
            <div className="grid_item">
              <h5>Info</h5>
              <Link to="/about">About</Link>
              {/* <Link to='/'>Services</Link> */}
              <Link to="/articles-and-interview">Publications</Link>
              <Link to="/media">Speaking Engagement</Link>
              {/* <Link to='/'>Resources</Link> */}
              <Link to="/contact">Contact</Link>
            </div>
            <div className="grid_item">
              <h5>Impact</h5>
              {services &&
                services
                  .filter((service) => service.slug !== "uddinfoundation")
                  .map((service) => (
                    <Link to={"/service/" + service?.slug} key={service._id}>
                      {service?.shortName}
                    </Link>
                  ))}
            </div>
            <div className="grid_item">
              <h5>Socials</h5>
              <SocialLinks />
            </div>
            <div className="grid_item">
              <h5>Contact</h5>

              <p>{contactInfo?.address}</p>
              <p>
                {contactInfo?.email}
                <br />
                {contactInfo?.phone}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_content bottom pb_4">
        <div className="container_fluid container">
          <p>
            Mostafiz Uddin © {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
