import { useEffect, useState } from 'react';
import axios from 'axios';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ABOUT_SLIDERS_API, IMAGE_URL } from '../../../../Utilities/APIs';

// import bnImg from '../../../../Assets/Images/Binary_001.png'

import 'swiper/css';
import 'swiper/css/pagination';
import './AbtSlider.css'

function AbtSlider() {

    const [aboutSliders, setAboutSliders] = useState(null);

    useEffect(() => {
        async function getAboutSliders() {
            const { data } = await axios.get(ABOUT_SLIDERS_API);
            setAboutSliders(data);
        }
        getAboutSliders()
    }, [])


    return (
        <section className="abt_slider_sc py_10 my_10">
            <div className="container_fluid container">
                <Swiper
                    modules={[Pagination]}
                    // centeredSlides={true}
                    // loop={true}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {aboutSliders?.map(item => (
                        <SwiperSlide key={item._id}>
                            <div className="grid_container">
                                <div className="grid_item">
                                    <img src={IMAGE_URL + item?.image} alt="icon" className='w_100' />
                                </div>
                                <div className="grid_item">
                                    <span>{item?.name}</span>
                                    <h5>{item?.title}</h5>
                                    <p>{item?.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    )
}

export default AbtSlider