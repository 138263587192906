// import thumbImg from '../../../Assets/Images/video-thumb.png'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { IMAGE_URL } from '../../../Utilities/APIs';
import playIcon from '../../../Assets/Icons/video-play.svg'
import Iframe from '../Iframe/Iframe';

import './Video.css'

function Video({parentApi}) {

  const [showIframe, setShowIframe] = useState(false)
  const [addVLink, setAddVLink] = useState(false)
  const [homeAboutVideos, setHomeAboutVideos] = useState(null);

  useEffect(() => {
    async function getHomeAboutVideos() {
      const { data } = await axios.get(parentApi);
      setHomeAboutVideos(data);
    }
    getHomeAboutVideos()
  }, [parentApi])

  const openIframe = (link) => {
    setShowIframe(true)
    setAddVLink(link)
  }
  const closeIframe = () => {
    setShowIframe(false)
    setAddVLink('')
  }


  return (
    <section className="video_sc py_10">
      <div className="container_fluid container">
        <div className="video">
          <img src={homeAboutVideos && IMAGE_URL + homeAboutVideos[0].image} alt="thumb" />
          <button type='button' className='play_btn' onClick={() => openIframe(homeAboutVideos && homeAboutVideos[0].videoLink)}>
            <img src={playIcon} alt="icon" />
          </button>
          {showIframe &&
            <Iframe
              videoLink={addVLink}
              closeFunc={closeIframe}
            />
          }
        </div>
      </div>
    </section>
  )
}

export default Video