

function SelectTags({ selectedTag, setSelectedTag, tags, allText }) {
  return (
    <div class="image_tag_select">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clip-path="url(#clip0_1297_372)">
          <path
            d="M11.5906 12.8383C11.2673 13.0312 10.7819 13.2084 10.6974 13.1644C10.0084 13.4218 9.45222 13.2687 9.26026 13.1599C9.22161 13.1772 9.02933 13.1343 8.83289 13.0648C8.25852 12.8614 8.10927 12.7267 5.12575 9.71928L2.48054 7.05289L2.01323 7.52027L1.54591 7.98759L4.20237 10.6757C6.94272 13.4487 7.25641 13.7419 7.82039 14.057C9.17005 14.8111 10.9393 14.7797 12.2633 13.9781C12.8493 13.6233 13.2443 13.2505 15.8744 10.57L18.4402 7.95499L17.9726 7.48741L17.5051 7.01983L14.7104 9.83219C12.0171 12.5425 11.9039 12.6516 11.5906 12.8383Z"
            fill="#222222"
          />
        </g>
        <defs>
          <clipPath id="clip0_1297_372">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <select
        value={selectedTag}
        onChange={(e) => setSelectedTag(e.target.value)}
        id="imageTagSelect"
        class="select-box"
      >
        <option value="all">{allText}</option>
        {tags?.map((tag) => (
          <option value={tag._id} key={tag._id}>
            {tag.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectTags;
