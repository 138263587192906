import "./HomeBanner.css";

function HomeBanner() {
  return (
    <section className="home_banner">
      {/* <section className="home_banner" style={{ backgroundImage: `url('${sliders && IMAGE_URL + sliders[0]?.image}')` }}> */}
      <div className="container_fluid container">
        <div className="banner_inner">
          <div className="large_title">
            <h2>To Change the World, You Have to Change Yourself First.</h2>
            <h2>The Best Way to Bring Change is to Lead by Example.</h2>
          </div>
          {/* <div className="list">
                        <span>{sliders && sliders[0]?.service1}</span>
                        <span>• {sliders && sliders[0]?.service2}</span>
                        <span>• {sliders && sliders[0]?.service3}</span>
                    </div>
                    <div className="text_button">
                        <p className='text'>{sliders && sliders[0]?.description}</p>
                        <div>
                            <Link to="/contact" className="btn_large bg_white">
                                <div>
                                    <span>{sliders && sliders[0]?.buttonText1}</span>
                                    <p>{sliders && sliders[0]?.buttonText2}</p>
                                </div>
                                <img src={iconArrow} alt="icon" />
                            </Link>
                        </div>
                    </div> */}
        </div>
      </div>
    </section>
  );
}

export default HomeBanner;
