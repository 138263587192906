import React from "react";
import "./InitiativesCard.css";
import iconArrow from "../../../../Assets/Icons/arrow-top-right.svg";
import bae from "../../../../Assets/Icons/bae.svg";
import bde from "../../../../Assets/Icons/bde.svg";
import bob from "../../../../Assets/Icons/bob.svg";
import mibw from "../../../../Assets/Icons/mibw.svg";
import saf from "../../../../Assets/Icons/saf.svg";
import bfse from "../../../../Assets/Icons/bfse.svg";
import bces from "../../../../Assets/Icons/bces.svg";
import din from "../../../../Assets/Icons/din.svg";
import bcaf from "../../../../Assets/Icons/bcaf.svg";
import das from "../../../../Assets/Icons/das.svg";
import dae from "../../../../Assets/Icons/dae.svg";
import rexc from "../../../../Assets/Icons/rexc.svg";

const initiativesData = [
  {
    title: "Bangladesh Apparel Exchange",
    image: bae,
    link: "https://bangladeshapparelexchange.com/",
  },
  {
    title: "Bangladesh Denim Expo",
    image: bde,
    link: "https://bangladeshdenimexpo.com/",
  },
  {
    title: "Best of Bangladesh",
    image: bob,
    link: "https://bestofbangladesh.org/",
  },
  {
    title: "Made in Bangladesh Week",
    image: mibw,
    link: "https://www.madeinbangladeshweek.com/",
  },
  {
    title: "Sustainable Apparel Forum",
    image: saf,
    link: "https://sustainableapparelforum.com/",
  },
  {
    title: "Bangladesh Fashionology Summit & Expo",
    image: bfse,
    link: "https://bangladeshfashionologysummit.com/",
  },
  {
    title: "Bangladesh Circular Economy Summit",
    image: bces,
    link: "https://bangladeshcirculareconomysummit.com/",
  },
  {
    title: "Denim Innovation Night",
    image: din,
    link: "https://www.youtube.com/watch?v=VkuxaWbcLPw",
  },
  {
    title: "Bangladesh Climate Action Forum",
    image: bcaf,
    link: "https://climateactionforumbd.com/",
  },
  {
    title: "Dhaka Apparel Summit",
    image: das,
    link: "https://bangladeshapparelexchange.com/events/dhaka-apparel-summit/",
  },
  {
    title: "Dhaka Apparel Expo",
    image: dae,
    link: "https://www.madeinbangladeshweek.com/event/dhaka-apparel-expo/",
  },
  {
    title: "Recycle Exchange",
    image: rexc,
    link: "https://recycleexchange.org/",
  },
];

const InitiativesCard = () => {
  return (
    <div className="initiatives_card">
      <div className="container_fluid container">
        <h1>Mostafiz's Journey of Initiatives</h1>
        <div className="nm_articles">
          {initiativesData.map((initiative, index) => (
            <div className="media_card" key={index}>
              <div className="media_card_image">
                <img
                  src={initiative.image}
                  alt={initiative.title}
                  className="thumb_img"
                />
              </div>
              <div className="content">
                <h3>{initiative.title}</h3>
              </div>
              <a
                href={initiative.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Learn More</span>
                <img src={iconArrow} alt="icon" />
              </a>
            </div>
          ))}
        </div>
        <div className="nm_articles_small">
          {initiativesData.map((initiative, index) => (
            <div className="media_card" key={index}>
              <div className="media_card_image">
                <img
                  src={initiative.image}
                  alt={initiative.title}
                  className="thumb_img"
                />
              </div>
              <div className="content">
                <h3>{initiative.title}</h3>
              </div>
              <a
                href={initiative.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Learn More</span>
                <img src={iconArrow} alt="icon" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InitiativesCard;
