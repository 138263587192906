import brandingImage_1 from "../Assets/Images/brandingPage/brandingImage_1.jpg";
import brandingImage_2 from "../Assets/Images/brandingPage/brandingImage_2.jpg";
import brandingImage_3 from "../Assets/Images/brandingPage/brandingImage_3.jpg";
import brandingImage_4 from "../Assets/Images/brandingPage/brandingImage_4.jpg";

import denimImage_1 from "../Assets/Images/denimPage/denimImage_1.jpg";
import denimImage_2 from "../Assets/Images/denimPage/denimImage_2.jpg";
import denimImage_3 from "../Assets/Images/denimPage/denimImage_3.jpg";
import denimImage_4 from "../Assets/Images/denimPage/denimImage_4.jpg";

import sustainabilityImage_1 from "../Assets/Images/sustainabilityPage/sustainabilityImage_1.jpg";
import sustainabilityImage_2 from "../Assets/Images/sustainabilityPage/sustainabilityImage_2.jpg";
import sustainabilityImage_3 from "../Assets/Images/sustainabilityPage/sustainabilityImage_3.jpg";
import sustainabilityImage_4 from "../Assets/Images/sustainabilityPage/sustainabilityImage_4.jpg";

import circularityImage_1 from "../Assets/Images/circularityPage/circularityImage_1.jpg";
import circularityImage_2 from "../Assets/Images/circularityPage/circularityImage_2.jpg";
import circularityImage_3 from "../Assets/Images/circularityPage/circularityImage_3.jpg";
import circularityImage_4 from "../Assets/Images/circularityPage/circularityImage_4.jpg";

import innovationImage_1 from "../Assets/Images/innovationPage/innovationImage_1.jpg";
import innovationImage_2 from "../Assets/Images/innovationPage/innovationImage_2.jpg";
import innovationImage_3 from "../Assets/Images/innovationPage/innovationImage_3.jpg";
import innovationImage_4 from "../Assets/Images/innovationPage/innovationImage_4.jpg";



const galleryImages = {
  "branding-bangladesh": [
    { src: brandingImage_1, alt: "Image 1" },
    { src: brandingImage_2, alt: "Image 2" },
    { src: brandingImage_3, alt: "Image 3" },
    { src: brandingImage_4, alt: "Image 4" },
  ],
  "denim": [
    { src: denimImage_1, alt: "Image 1" },
    { src: denimImage_2, alt: "Image 2" },
    { src: denimImage_3, alt: "Image 3" },
    { src: denimImage_4, alt: "Image 4" },
  ],
  "sustainability": [
    { src: sustainabilityImage_1, alt: "Image 1" },
    { src: sustainabilityImage_2, alt: "Image 2" },
    { src: sustainabilityImage_3, alt: "Image 3" },
    { src: sustainabilityImage_4, alt: "Image 4" },
  ],
  "circularity": [
    { src: circularityImage_1, alt: "Image 1" },
    { src: circularityImage_2, alt: "Image 2" },
    { src: circularityImage_3, alt: "Image 3" },
    { src: circularityImage_4, alt: "Image 4" },
  ],
  "innovation": [
    { src: innovationImage_1, alt: "Image 1" },
    { src: innovationImage_2, alt: "Image 2" },
    { src: innovationImage_3, alt: "Image 3" },
    { src: innovationImage_4, alt: "Image 4" },
  ],
};

export default galleryImages;
