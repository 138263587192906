import { useEffect, useState } from 'react';
import axios from 'axios';
import ImpactCards from '../../../Layouts/ImpactCards/ImpactCards'
import ImpactCard from '../../../Layouts/ImpactCard/ImpactCard'
import { ABOUT_IMPACTS_API, ABOUT_IMPACT_TITLES_API } from '../../../../Utilities/APIs';
import './AbtImpatcs.css'

function AbtImpatcs() {

    const [aboutImpacts, setAboutImpacts] = useState(null);
    const [impactTitle, setImpactTitle] = useState('')

    useEffect(() => {
        async function getAboutImpacts() {
            const { data } = await axios.get(ABOUT_IMPACTS_API);
            setAboutImpacts(data);
        }
        getAboutImpacts()
    }, [])

    useEffect(() => {
        async function getImpactTitle() {
            const { data } = await axios.get(ABOUT_IMPACT_TITLES_API);
            setImpactTitle(data);
        }
        getImpactTitle()
    }, [])


    return (
        <section className="foot_print py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper mb_7">
                    <h1 className="title_60 center">{impactTitle[0]?.title}</h1>
                </div>
                <ImpactCards>
                    {aboutImpacts?.map(item => (
                        <ImpactCard
                        key={item._id}
                            number={item.mainText}
                            title={item.subText}
                        />
                    ))}
                </ImpactCards>
            </div>
        </section>
    )
}

export default AbtImpatcs