import { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import MediaCard from '../MediaCard/MediaCard';
import { BLOGS_API } from '../../../Utilities/APIs';

import 'swiper/css';
import 'swiper/css/navigation';
import './BlogsSc.css';

function BlogsSc({ slug, scTtitle, scSubTitle }) {

    const [Blogs, setBlogs] = useState(null);

    useEffect(() => {
        async function getBlogs() {
            const { data } = await axios.get(BLOGS_API);
            setBlogs(data?.filter(blg => blg.service?.slug === slug));
        }
        getBlogs()
    }, [slug])


    return (
        <section className="blogs_sc white_pagination py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper with_flex right_bottom mb_5">
                    <div className="flex_item">
                        <h4 className="title_24">{scSubTitle}</h4>
                        <h1 className="title_60">{scTtitle}</h1>
                    </div>
                    <div className="flex_item">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={{
                        prevEl: `.blogs_sc .swiper-button-prev`,
                        nextEl: `.blogs_sc .swiper-button-next`
                    }}
                    pagination={{
                        el: '.blogs_sc .swiper-pagination',
                        clickable: true,
                    }}

                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }}
                >
                    {Blogs &&
                        Blogs?.map(blog => (
                            <SwiperSlide key={blog._id}>
                                <MediaCard 
                                img={blog?.image}
                                date={blog?.publishDate}
                                tag={blog?.tag}
                                name={blog?.name}
                                link={blog?.link}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            <div className="swiper-pagination"></div>
        </section>
    )
}

export default BlogsSc