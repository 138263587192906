import './ImpactCards.css'

function ImpactCards({children}) {
  return (
    <div className='impat_cards'>
      {children}
    </div>
  )
}

export default ImpactCards