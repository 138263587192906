import "./ImageGallery.css";

const ImageGallery = ({ images }) => {

  if (!images || images.length === 0) return null;

  return (
    <div className="container_fluid container">
      <div className="image-gallery">
        <div className="leftSideImage">
          <div className="image-item item-1">
            <img src={images[0].src} alt={images[0].alt} />
          </div>
        </div>
        <div className="rightSideImage">
          <div className="topSideImage">
            <div className="image-item item-2">
              <img src={images[1].src} alt={images[1].alt} />
            </div>
            <div className="image-item item-3">
              <img src={images[2].src} alt={images[2].alt} />
            </div>
          </div>
          <div className="bottomSideImage">
            <div className="image-item item-4">
              <img src={images[3].src} alt={images[3].alt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
