import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AppContext from '../../../../AppContext/AppContext';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SUCCESS_STORIES_API } from '../../../../Utilities/APIs';
import SSCard from '../../../Layouts/SuccessStory/SSCard/SSCard';

import 'swiper/css';
import 'swiper/css/navigation';
import '../../../Layouts/SuccessStory/SuccessStory.css'

function FeaturedSuccessStory() {

    const { secTitles } = useContext(AppContext)
    const [showIframe, setShowIframe] = useState(false)
    const [addVLink, setAddVLink] = useState(false)
    const [itemID, setItemID] = useState('')
    const [featuredSuccessStory, setFeaturedSuccessStory] = useState(null);

    useEffect(() => {
        async function getFeaturedSuccessStory() {
            const { data } = await axios.get(SUCCESS_STORIES_API);
            setFeaturedSuccessStory(data);
        }
        getFeaturedSuccessStory()
    }, [])

    const openIframe = (link, id) => {
        setShowIframe(true)
        setAddVLink(link)
        setItemID(id)
    }
    const closeIframe = () => {
        setShowIframe(false)
        setAddVLink('')
        setItemID('')
    }

    
    return (
        <section className="success_story_sc white_pagination py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper with_flex right_bottom mb_5">
                    <div className="flex_item">
                        <h4 className="title_24">{secTitles?.successStorySectionSubtitle}</h4>
                        <h1 className="title_60">{secTitles?.successStorySectiontitle}</h1>
                    </div>
                    <div className="flex_item">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={{
                        prevEl: `.success_story_sc .swiper-button-prev`,
                        nextEl: `.success_story_sc .swiper-button-next`
                    }}
                    pagination={{
                        el: '.success_story_sc .swiper-pagination',
                        clickable: true,
                    }}

                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        }
                    }}
                >
                    {featuredSuccessStory &&
                        featuredSuccessStory?.map(sStory => (
                            <SwiperSlide key={sStory._id}>
                                <SSCard
                                    content={sStory}
                                    showIframe={showIframe}
                                    addVLink={addVLink}
                                    openIframe={openIframe}
                                    closeIframe={closeIframe}
                                    itemID={itemID}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            <div className="swiper-pagination"></div>
        </section>
    )
}

export default FeaturedSuccessStory