import { Link } from "react-router-dom"

import iconArrow from '../../../Assets/Icons/arrow-right-white.svg'

function LargeBtn({ link, thinText, boldText, isExternal }) {
    return (
        <>
            {!isExternal &&
                <Link to={link} className="btn_large bg_purple">
                    <div>
                        <span>{thinText}</span>
                        <p>{boldText}</p>
                    </div>
                    <img src={iconArrow} alt="icon" />
                </Link>
            }
            {isExternal &&
                <a href={link} className="btn_large bg_purple" target="_blanck">
                    <div>
                        <span>{thinText}</span>
                        <p>{boldText}</p>
                    </div>
                    <img src={iconArrow} alt="icon" />
                </a>
            }
        </>

    )
}

export default LargeBtn