import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AppContext from '../../../../AppContext/AppContext';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ARTICLES_API, FEATURED_ARTICLES_API } from '../../../../Utilities/APIs';
import MediaCard from '../../../Layouts/MediaCard/MediaCard';

import 'swiper/css';
import 'swiper/css/navigation';
import './FeaturedArticle.css'


function FeaturedArticle() {

    const { secTitles } = useContext(AppContext)
    const [featuredArticles, setFeaturedArticles] = useState(null);

    useEffect(() => {
        async function getFeaturedArticles() {
            // const { data } = await axios.get(ARTICLES_API);
            const { data } = await axios.get(ARTICLES_API + '/getArticlesForHome');
            setFeaturedArticles(data);
        }
        getFeaturedArticles()
    }, [])


    return (
        <section className="articles_sc white_pagination py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper with_flex right_bottom mb_5">
                    <div className="flex_item">
                        <h4 className="title_24">{secTitles?.mediaSectionSubtitle}</h4>
                        <h1 className="title_60">{secTitles?.mediaSectiontitle}</h1>
                    </div>
                    <div className="flex_item">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>
                <Swiper
                
                    modules={[Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={{
                        prevEl: `.articles_sc .swiper-button-prev`,
                        nextEl: `.articles_sc .swiper-button-next`
                    }}
                    pagination={{
                        el: '.articles_sc .swiper-pagination',
                        clickable: true,
                    }}

                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }}
                >

                    {featuredArticles &&
                        featuredArticles?.map(featuredArticle => (
                            <SwiperSlide key={featuredArticle._id}>
                                <MediaCard
                                    img={featuredArticle?.image}
                                    date={featuredArticle?.publishDate}
                                    tag={featuredArticle?.tag}
                                    name={featuredArticle?.name}
                                    link={featuredArticle?.link}
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className="swiper-pagination"></div>
        </section>
    )
}

export default FeaturedArticle