import MediaCard from "../../../Layouts/MediaCard/MediaCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { ARTICLES_API, ARTICLE_TAGS_API } from "../../../../Utilities/APIs";
import Tags from "../Tags/Tags";
import SelectTags from "../SelectTags/SelectTags";
import MoreButton from "../MoreButton/MoreButton";

import "./NMArticles.css";
import Navbar from "../../../Partials/Navbar/Navbar";

function NMArticles() {
  const [selectedTag, setSelectedTag] = useState("all");

  const [showArticles, setShowArticles] = useState(6);
  const [showMobileArticles, setshowMobileArticles] = useState(3);

  const [articleTags, setArticleTags] = useState(null);
  const [articles, setArticles] = useState(null);

  const filterDatas = articles?.filter(
    (article) => selectedTag === "all" || article.articleTag === selectedTag
  );

  useEffect(() => {
    async function getTestimonials() {
      const { data } = await axios.get(ARTICLE_TAGS_API);
      const filteredTags = data.filter(
        (tag) => tag.name !== "Uddin Foundation"
      );
      setArticleTags(filteredTags);
    }
    getTestimonials();
  }, []);

  useEffect(() => {
    async function getArticles() {
      const { data } = await axios.get(ARTICLES_API);
      setArticles(data);
    }
    getArticles();
  }, []);

  const handleClick = (tagId) => {
    setSelectedTag(tagId);
  };

  const handleLoadMore = () => {
    setShowArticles((prevCount) => prevCount + 6);
  };

  const handleClickForSmall = () => {
    setshowMobileArticles((prevCount) => prevCount + 3);
  };

  return (
    <div>
      <Navbar />
      <div className="image_gallery">
        <div className="container_fluid container">
          <h1>Articles</h1>

          <Tags
            selectedTag={selectedTag}
            handleClick={handleClick}
            allText="All Articles"
            tags={articleTags}
          />

          <SelectTags
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            allText="All Articles"
            tags={articleTags}
          />
          <div className="nm_articles">
            {filterDatas?.slice(0, showArticles)?.map((article) => (
              <MediaCard
                key={article._id}
                img={article?.image}
                date={article?.publishDate}
                tag={article?.tag}
                name={article?.name}
                link={article?.link}
              />
            ))}
          </div>
          <div className="nm_articles_small">
            {filterDatas?.slice(0, showMobileArticles)?.map((article) => (
              <MediaCard
                key={article._id}
                img={article?.image}
                date={article?.publishDate}
                tag={article?.tag}
                name={article?.name}
                link={article?.link}
              />
            ))}
          </div>
          {filterDatas?.length > showArticles && (
            <MoreButton
              handleClick={handleLoadMore}
              text=" Load More"
              isLarge
            />
          )}
          {filterDatas?.length > showMobileArticles && (
            <MoreButton
              handleClickForSmall={handleClickForSmall}
              text=" Load More"
              isSmall
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default NMArticles;
