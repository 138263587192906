function MoreButton({
  handleClickForSmall,
  handleClick,
  text,
  isSmall,
  isLarge
}) {
  return (
    <>
      {isLarge && (
        <div className="g_btn large_btn">
          <button onClick={handleClick}>
            <span>{text}</span>
          </button>
        </div>
      )}
      {isSmall && (
        <div className="g_btn small_btn">
          <button onClick={handleClickForSmall}>
            <span>{text}</span>
          </button>
        </div>
      )}
    </>
  );
}

export default MoreButton;
