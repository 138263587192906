import MediaCard from "../../../Layouts/MediaCard/MediaCard";
import "./NMReports.css";
import report1 from "../../../../Assets/Images/reports/Bangladesh Report.pdf";
import report2 from "../../../../Assets/Images/reports/Circularity Report.pdf";
import pdf from "../../../../Assets/Images/reports/BCES_Circularity-Report-2024.pdf";
import image from "../../../../Assets/Images/reports/Post-Event-Report.png";
import image1 from "../../../../Assets/Images/reports/Bangladesh Progress Revised.webp";
import Navbar from "../../../Partials/Navbar/Navbar";

function NMReports() {
  const reports = [
    {
      id: "1",
      img: "https://mostafiz.org/static/media/Bangladesh%20Progress%20Revised.fe975fc9be8d4b27210b.webp",
      date: "2024-10-10",
      tag: "Nation Branding",
      name: "Bangladesh Benchmarking Garment Industry Progress",
      link: "https://mostafiz.org/static/media/Bangladesh%20Report.9eee003fc7cd3a5969c3.pdf",
    },
    {
      id: "2",
      img: "https://mostafiz.org/static/media/Post-Event-Report.83e83f28766a74e1ca80.png",
      date: "2024-06-11",
      tag: "Circularity",
      name: "Fashion, Circular Economy & Bangladesh",
      link: "https://mostafiz.org/static/media/Circularity%20Report.3548e07478f037d1632b.pdf",
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="reports_gallery">
        <div className="container_fluid container">
          <h1>Reports</h1>
          {/* <a href={report1}>Reports 1</a>
        <a href={report2}>Reports 2</a> */}
          <div className="nm_reports">
            {reports?.map((report) => (
              <MediaCard
                key={report.id}
                img={report.img}
                date={report?.date}
                tag={report?.tag}
                name={report?.name}
                link={report?.link}
              />
            ))}
          </div>

          <div className="nm_reports_small">
            {reports?.map((report) => (
              <MediaCard
                key={report.id}
                img={report.img}
                date={report?.date}
                tag={report?.tag}
                name={report?.name}
                link={report?.link}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NMReports;
