import { IMAGE_URL } from '../../../../Utilities/APIs'
import playIcon from '../../../../Assets/Icons/video-play-white.svg'
import elIcon from '../../../../Assets/Icons/external-link.svg'
import Iframe from '../../Iframe/Iframe'

import './SSCard.css'

function SSCard({ content, showIframe, addVLink, openIframe, closeIframe, itemID }) {


    return (
        <div className='ss_card'>
            <img src={content && IMAGE_URL + content?.image} alt="thumb" className='thum_img' />
            <div className="content">
                <div>
                    <h2>
                        <span>{content && content?.name}</span>
                    </h2>

                    {content?.linkType === "videoLink" &&
                        <button onClick={() => openIframe(content && content?.link, content && content?._id)}>
                            <img src={playIcon} alt="icon" />
                        </button>
                    }
                    {content?.linkType === "newsLink" &&
                        <a href={content?.link} target='_blanck'>
                            <img src={elIcon} alt="icon" />
                        </a>
                    }

                    {/* {content?.videoLink
                        ?
                        <button onClick={() => openIframe(content && content?.videoLink, content && content?._id)}>
                            <img src={playIcon} alt="icon" />
                        </button>
                        :
                        <a href={content?.newsLink} target='_blanck'>
                            <img src={elIcon} alt="icon" />
                        </a>
                    } */}
                </div>
            </div>
            {showIframe && (content?._id === itemID) &&
                <Iframe
                    videoLink={addVLink}
                    closeFunc={closeIframe}
                />
            }
        </div>
    )
}

export default SSCard