// import img1 from '../../../../Assets/Images/daily-logos/indipendent.png'
// import img2 from '../../../../Assets/Images/daily-logos/d-star.png'
// import img3 from '../../../../Assets/Images/daily-logos/time.png'
// import img4 from '../../../../Assets/Images/daily-logos/elle.png'
// import img5 from '../../../../Assets/Images/daily-logos/d-tarbine.png'

import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import AppContext from '../../../../AppContext/AppContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { IMAGE_URL, WORK_FEATURES_API } from '../../../../Utilities/APIs'

import 'swiper/css';
import './FeaturedIn.css'

function FeaturedIn() {

    const { secTitles } = useContext(AppContext)
    const [workFeatures, setWorkFeatures] = useState(null);

    useEffect(() => {
        async function getWorkFeatures() {
            const { data } = await axios.get(WORK_FEATURES_API);
            setWorkFeatures(data);
        }
        getWorkFeatures()
    }, [])

    return (
        <section className="featured_in pt_8 pb_10">
            <div className="container_fluid container">
                <h1 className='mb_6'>{secTitles?.workFeaturetitle}</h1>
                <div className="featured_slider_wrapper">
                    <Swiper
                        // loop={true}
                        speed={3000}
                        rewind={true}
                        spaceBetween={30}
                        slidesPerView={2.5}
                        autoplay={{
                            delay: 2000
                        }}
                        modules={[Autoplay]}
                        breakpoints={{
                            768: {
                                slidesPerView: 4,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {
                            workFeatures?.map(wf =>
                                <SwiperSlide key={wf._id}>
                                    <div className="featued_logo" >
                                        <img src={IMAGE_URL + wf.image} alt="brand" />
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                </div>
                {/* <div className="featued_logos">
                    {
                        workFeatures?.map(wf =>
                            <div className="flex_item" key={wf._id}>
                                <img src={IMAGE_URL + wf.image} alt="brand" />
                            </div>
                        )
                    }

                </div> */}
            </div>
        </section>
    )
}

export default FeaturedIn