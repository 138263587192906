import { useEffect, useState } from 'react';
import axios from 'axios';

import './Events.css'
import { EVENTS_API } from '../../../../Utilities/APIs';

function Events({ slug, scTtitle }) {

    const [events, setEvents] = useState(null);
    const [activeEventId, setActiveEventId] = useState('');

    useEffect(() => {
        async function getEvents() {
            const { data } = await axios.get(EVENTS_API);
            setEvents(data?.filter(evnt => evnt.service?.slug === slug));
            setActiveEventId(data?.filter(evnt => evnt.service?.slug === slug)[0]?._id);
        }
        getEvents()
    }, [slug])


    return (
        <section className="events py_10">
            <div className="container_fluid container">
                <div className="grid_1_3">
                    <div className="grid_item">
                        <h2 className="title_48">{scTtitle}</h2>
                    </div>
                    <div className="grid_item">
                        <div className="accordion">
                            {events &&
                                events?.map(event => (
                                    <div className={`accordion_item ${event._id === activeEventId && 'open'}`} key={event._id}>
                                        <div className="accordion_header">
                                            <h4
                                                onClick={() => setActiveEventId(event._id)}
                                            >
                                                {event && event?.name}
                                            </h4>
                                        </div>
                                        <div className="accordion_body">
                                            <div className="accordion_body_inner">
                                                <p>{event && event?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* <div className="accordion_item open">
                                <div className="accordion_header">
                                    <h4>Bangladesh Denim Expo</h4>
                                </div>
                                <div className="accordion_body">
                                    <p>Bangladesh Denim Expo has been organized by Mr. Mostafiz Uddin, managing director of Denim Expert Ltd., as a non-profit exercise. The Expo’s objective is to showcase Bangladesh’s growth potential in the denim industry. Bangladesh is well-known for denim and jeans production, for both niche and mass markets. Buyers respect the Bangladeshi ability to offer great margins and high-quality goods.</p>
                                </div>
                            </div>
                            <div className="accordion_item">
                                <div className="accordion_header">
                                    <h4>Bangladesh Denim Expo</h4>
                                </div>
                                <div className="accordion_body">
                                    <p>Bangladesh Denim Expo has been organized by Mr. Mostafiz Uddin, managing director of Denim Expert Ltd., as a non-profit exercise. The Expo’s objective is to showcase Bangladesh’s growth potential in the denim industry. Bangladesh is well-known for denim and jeans production, for both niche and mass markets. Buyers respect the Bangladeshi ability to offer great margins and high-quality goods.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Events