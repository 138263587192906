import { useEffect, useState } from "react";
import { IMPACTS_API } from "../../../../Utilities/APIs";
import ImpactCard from "../../../Layouts/ImpactCard/ImpactCard"
import ImpactCards from "../../../Layouts/ImpactCards/ImpactCards"
import axios from "axios";

function SrvImpacts({slug, scTtitle}) {

    const [impacts, setImpatcs] = useState(null);

    useEffect(() => {
        async function getImpatcs() {
            const { data } = await axios.get(IMPACTS_API);
            setImpatcs(data?.filter(imp => imp.service?.slug === slug));
        }

        getImpatcs()
    }, [slug])


    return (
        <section className="foot_print py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper mb_7">
                    <h1 className="title_60 center">{scTtitle}</h1>
                </div>
                <ImpactCards>
                    {impacts?.map(impact => (
                        <ImpactCard
                        key={impact._id}
                            number={impact.mainText}
                            desc={impact.subText}
                        />
                    ))}
                </ImpactCards>
            </div>
        </section>
    )
}
export default SrvImpacts