import { useEffect, useState } from 'react';
import axios from 'axios';
import Number from '../Number/Number'

import './Programs.css'
import { PROGRAMS_API } from '../../../../Utilities/APIs';

function Programs({ slug, scTtitle }) {

    const [programs, setPrograms] = useState(null);

    useEffect(() => {
        async function getPrograms() {
            const { data } = await axios.get(PROGRAMS_API);
            setPrograms(data?.filter(prgm => prgm.service?.slug === slug));
        }
        getPrograms()
    }, [slug])

    return (
        <section className="programs py_10 my_10">
            <div className="container_fluid container">
                <div className="sch_wrapper mb_7">
                    <h2 className="title_48 center">{scTtitle}</h2>
                </div>

                <div className="grid_container">
                    {programs &&
                        programs?.map(program => (
                            <div className="svr_ovrv_card" key={program._id}>
                                <Number text={program && program?.precedence} />
                                <h4>{program && program?.title}</h4>
                                <p>{program && program?.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Programs