import globe from "../../../../Assets/Icons/globe.svg";
import dream1 from "../../../../Assets/Icons/dream1.svg";
import dream2 from "../../../../Assets/Icons/dream2.svg";

import "./Dreams.css";
const Dreams = () => {
  return (
    <div className="container container_fluid">
      <div id="hr">
        <div className="dream_container">
          <div className="left-side">
            <h2>Mostafiz Uddin has</h2>
            <div className="highlight">
              <span className="highlight-text">two dreams</span>
              <img className="emoji" src={globe} alt="" />
            </div>
          </div>
          <div className="right-side">
            <div className="dream-side">
              <div className="dream1">
                <img src={dream1} alt="dream1" />
                <p className="dream_text2">
                  To make his country a better place
                </p>
              </div>
              <div className="dream2">
                <img src={dream2} alt="dream2" />
                <p className="dream_text2">
                  To turn the global apparel and textile industry into a
                  responsible & sustainable entity
                </p>
              </div>
            </div>

            <div className="passion">
              <div></div>
              <p>
                All of his passion and energy goes into the pursuit of these
                dreams.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dreams;
