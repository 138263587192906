import { IMAGE_URL } from '../../../../../Utilities/APIs'

import './TestmCard.css'

function TestmCard({testimonial}) {
  return (
    <div className='tstm_card'>
        <div className="img_box">
            <img src={IMAGE_URL + testimonial.image} alt="thumb" />
        </div>
        <p>"{testimonial.description}"</p>
        <h5>{testimonial.name}</h5>
        <span>{testimonial.designation}</span>
    </div>
  )
}

export default TestmCard