import { IMAGE_URL } from '../../../../Utilities/APIs'
import './TitleDescImg.css'

function TitleDescImg({ title, desc, imgSrc }) {
    return (
        <section className="title_desc_img py_8">
            <div className="container_fluid container">
                <div className="grid_1_3">
                    <div className="grid_item">
                        <h2 className='title_48'>{title}</h2>
                    </div>
                    <div className="grid_item">
                        <p>{desc}</p>
                        {imgSrc &&
                            <div className="img_box">
                                <img src={IMAGE_URL + imgSrc} alt="thumb" className='w_100' />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TitleDescImg