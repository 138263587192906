import React from "react";
import ImageGallery from "./ImageGallery/ImageGallery";
import Navbar from "../../Partials/Navbar/Navbar";
// import NMArticles from "./NMArticles/NMArticles";
// import NMSuccessStory from "./NMSuccessStory/NMSuccessStory";

function Media() {
  return (
    <div>
      <Navbar />
      <ImageGallery />
      {/* <NMArticles />
      <NMSuccessStory /> */}
    </div>
  );
}

export default Media;
