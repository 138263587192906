import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { HOME_SECTIONS_API, SERVICES_API } from "../Utilities/APIs";

const AppContext = createContext({})


function AppContextProvider({ children }) {

    const [showModal, setShowModal] = useState(false)
    const [showIframe, setShowIframe] = useState(false)
    const [videoLink, setVideoLink] = useState('')
    const [services, setServices] = useState(null);
    const [itemId, setItemId] = useState('');
    const [secTitles, setSecTitles] = useState(null)

    useEffect(() => {
        async function getServices() {
            const { data } = await axios.get(SERVICES_API);
            setServices(data);
        }
        getServices()
    }, [])

    useEffect(() => {
        async function getScTitles() {
            const { data } = await axios.get(HOME_SECTIONS_API);
            setSecTitles(data[0]);
        }
        getScTitles()
    }, [])

    const showModalSetVideoLink = (link) => {
        setShowModal(true)
        setVideoLink(link)
    }
    const hideModalRemoveVideoLink = () => {
        setShowModal(false)
        setVideoLink('')
    }

    const showIframeSetVideoLink = (link) => {
        setShowIframe(true)
        setVideoLink(link)
    }
    const hideIframeRemoveVideoLink = () => {
        setShowIframe(false)
        setVideoLink('')
    }


    return (
        <AppContext.Provider
            value={{
                showModal,
                setShowModal,
                videoLink,
                setVideoLink,
                showModalSetVideoLink,
                hideModalRemoveVideoLink,
                showIframe,
                setShowIframe,
                showIframeSetVideoLink,
                hideIframeRemoveVideoLink,
                itemId, 
                setItemId,
                services,
                secTitles
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export {
    AppContextProvider
}

export default AppContext;