import arrowIcon from '../../../../../Assets/Icons/arrow-right-black.svg'
// import exibImg from '../../../../../Assets/Images/exib-logo.png'
import { IMAGE_URL } from '../../../../../Utilities/APIs'

import './ExhibitionCard.css'

function ExhibitionCard({exhibition}) {
    return (
        <div className='exhib_card'>
            <div className="img_box">
                <img src={IMAGE_URL + exhibition?.image} alt="" className='' />
            </div>
            <div className="content">
                <span>{new Date(exhibition?.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                <h5>{exhibition?.title}</h5>
                <p>{exhibition?.location}</p>
            </div>
            <a href={exhibition?.websiteLink} target='_blanck'>
                <span>Visit Website</span>
                <img src={arrowIcon} alt="icon" />
            </a>
        </div>
    )
}

export default ExhibitionCard