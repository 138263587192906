import React from "react";
import Navbar from "../../Partials/Navbar/Navbar";
import InitiativesCard from "./InitiativesCard/InitiativesCard";

const Initiatives = () => {
  return (
    <>
      <Navbar />
      <InitiativesCard />
    </>
  );
};

export default Initiatives;
