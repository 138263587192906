import { useState, useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import AppContext from "../../../AppContext/AppContext";

import hambIcon from "../../../Assets/Icons/hamb.svg";
import logoWhite from "../../../Assets/Images/logo.svg";
import logoBlack from "../../../Assets/Images/logo-black.svg";

import "./Navbar.css";
import { CaretDown } from "@phosphor-icons/react";

function Navbar({ isTransparent }) {
  const { services } = useContext(AppContext);

  const [isNavShow, setIsNavShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isImpactDropdownOpen, setIsImpactDropdownOpen] = useState(false); // Separate state for Impact dropdown
  const [isPublicationsDropdownOpen, setIsPublicationsDropdownOpen] =
    useState(false); // Separate state for Publications dropdown

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  const handleNavToggle = () => {
    setIsNavShow((prevState) => !prevState);
  };

  const handleDropdownMouseEnter = (setDropdownOpen) => {
    setDropdownOpen(true); // Open dropdown on hover
  };

  const handleDropdownMouseLeave = (setDropdownOpen) => {
    setDropdownOpen(false); // Close dropdown when mouse leaves
  };

  const filteredServices = services?.filter(
    (service) => service.slug !== "uddinfoundation"
  );

  return (
    <section
      className={`navbar ${(isNavShow || scroll) && "bg_white"} ${
        isTransparent && "transparent"
      }`}
    >
      <div className="container_fluid container">
        <div className="flex_container">
          <Link to="/" className="logo_wrapper">
            <img src={logoWhite} alt="brand" className="logo_white" />
            <img src={logoBlack} alt="brand" className="logo_black" />
          </Link>
          {/* Destop View */}
          <nav className={`largenav ${isNavShow && "show"}`}>
            {/* About Us Page Link */}
            <NavLink to="/about">About</NavLink>
            {/* Branding Bangladesh Page Link */}
            {filteredServices?.[0] && (
              <NavLink
                key={filteredServices[0]._id}
                to={"/service/" + filteredServices[0].slug}
              >
                {filteredServices[0].shortName}
              </NavLink>
            )}

            {/* Impact Pages Dropdown Link */}
            <div
              className="custom-dropdown"
              onMouseEnter={() =>
                handleDropdownMouseEnter(setIsImpactDropdownOpen)
              }
              onMouseLeave={() =>
                handleDropdownMouseLeave(setIsImpactDropdownOpen)
              }
              style={{ position: "relative", display: "inline-block" }}
            >
              {/* Static dropdown trigger for "Impact" */}
              <NavLink className="dropdown-trigger">
                Impact <CaretDown size={20} weight="bold" />
              </NavLink>

              {/* Dropdown menu for Impact */}
              {isImpactDropdownOpen && (
                <div className="dropdown-menu">
                  {filteredServices?.slice(1).map((service) => (
                    <div
                      key={service._id}
                      className="dropdown-item"
                      onClick={() =>
                        (window.location.href = "/service/" + service.slug)
                      }
                    >
                      {service.shortName}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <NavLink to="/initiatives">Initiatives</NavLink>

            {/* Article and Interview */}
            <div
              className="custom-dropdown"
              onMouseEnter={() =>
                handleDropdownMouseEnter(setIsPublicationsDropdownOpen)
              }
              onMouseLeave={() =>
                handleDropdownMouseLeave(setIsPublicationsDropdownOpen)
              }
              style={{ position: "relative", display: "inline-block" }}
            >
              {/* Static dropdown trigger for "Publications" */}
              <NavLink className="dropdown-trigger">
                Publications <CaretDown size={20} weight="bold" />
              </NavLink>

              {/* Dropdown menu for Publications */}
              {isPublicationsDropdownOpen && (
                <div className="dropdown-menu">
                  <div
                    onClick={() => (window.location.href = "/articles/")}
                    className="dropdown-item"
                  >
                    Articles
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => (window.location.href = "/news-and-interview/")}
                  >
                    News & Interviews
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => (window.location.href = "/reports/")}
                  >
                    Reports
                  </div>
                </div>
              )}
            </div>

            <NavLink to="/media">Speaking Engagement</NavLink>
          </nav>

          {/* Mobile View */}
          <nav className={`smallnav ${isNavShow && "show"}`}>
            <NavLink to="/about">About</NavLink>
            {filteredServices?.map((service) => (
              <NavLink
                key={service._id}
                to={"/service/" + service.slug}
                onClick={handleNavToggle}
              >
                {service.shortName}
              </NavLink>
            ))}
            <NavLink to="/initiatives">Initiatives</NavLink>
            <NavLink to="/articles-and-interview">Publications</NavLink>
            <NavLink to="/media">Speaking Engagement</NavLink>
            <Link
              to="/contact"
              className="nav_cont_btn"
              onClick={handleNavToggle}
            >
              <span>contact</span>
            </Link>
          </nav>

          <div>
            <Link to="/contact" className="nav_cont_btn">
              <span>contact</span>
            </Link>
          </div>
          <button className="btn_menu_toggle" onClick={handleNavToggle}>
            <img src={hambIcon} alt="icon" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
