import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination, Autoplay } from 'swiper';
import axios from "axios";
import { BOOKS_SLIDERS_API, IMAGE_URL } from '../../../../Utilities/APIs';
import LargeBtn from '../../../Layouts/LargeBtn/LargeBtn'

import 'swiper/css';
import 'swiper/css/pagination';
import '../../../Layouts/OverflowSc/OverflowSc.css'

function Books() {

    const [books, setBooks] = useState(null);

    useEffect(() => {
        async function getBooks() {
            const { data } = await axios.get(BOOKS_SLIDERS_API);
            setBooks(data);
        }

        getBooks()
    }, [])


    return (
        <section className="overflow_sc my_10">
            <div className='height_only'></div>
            <div className="bg_black content pb_8">
                <div className="container_fluid container">
                    <Swiper
                        speed={1500}
                        spaceBetween={0}
                        slidesPerView={1}
                        effect={'fade'}
                        autoplay={{
                            delay: 2500
                        }}
                        // autoplay={false}
                        pagination={{
                            el: '.overflow_sc .swiper-pagination',
                            clickable: true,
                        }}
                        modules={[EffectFade, Pagination, Autoplay]}
                    >
                        {books?.map(book => (
                            <SwiperSlide key={book._id}>
                                <div className="grid_container">
                                    <div className="grid_item">
                                        <div className="img_box">
                                            <img src={IMAGE_URL + book?.image} alt="thumb" />
                                        </div>
                                    </div>
                                    <div className="grid_item">
                                        <h1 className='title_48 fc_white'>{book?.name}</h1>
                                        <p className='text fc_white'>{book?.description}</p>
                                        <div className="img_box">
                                            <img src={IMAGE_URL + book?.image} alt="thumb" />
                                        </div>
                                        <LargeBtn
                                            link={book?.link}
                                            thinText={book?.buttonText1}
                                            boldText={book?.buttonText2}
                                            isExternal
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    )
}

export default Books