import { backendUrl, spacesBucketUrl } from "../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'

export const EMPLOYEES_API = API_URL + 'employees/'
export const DESIGNATIONS_API = API_URL + 'designations/'
export const EMPLOYEE_DESIGNATIONS_API = API_URL + 'employeeDesignations/'
export const CUSTOMERS_API = API_URL + 'customers/'

export const APP_SETTINGS_API = API_URL + 'appSettings/'

export const HOME_SLIDERS_API = API_URL + 'homeSliders/'
export const HOME_VIDEOS_API = API_URL + 'homeVideos/'
export const HOME_SECTIONS_API = API_URL + 'homePageSectionTitles/'

export const ABOUTS_API = API_URL + 'abouts/'
export const ABOUT_VIDEOS_API = API_URL + 'aboutVideos/'
export const ABOUT_MAINTEXT_API = API_URL + 'aboutTopSectionMainTexts/'
export const ABOUT_CHANGETEXT_API = API_URL + 'aboutTopSectionChangingTexts/'
export const ABOUT_IMPACTS_API = API_URL + 'aboutImpacts/'
export const ABOUT_IMPACT_TITLES_API = API_URL + 'aboutImpactTitles/'

export const ABOUT_SLIDERS_API = API_URL + 'aboutSliders/'
export const MAGAZINES_SLIDERS_API = API_URL + 'magazineSliders/'
export const BOOKS_SLIDERS_API = API_URL + 'bookSliders/'

export const ABOUT_WRITE_UPS_API = API_URL + 'aboutWriteUps/'
export const TEXT_SLIDERS_API = API_URL + 'textSliders/'
export const WORK_FEATURES_API = API_URL + 'workFeatures/'
export const TESTIMONIALS_API = API_URL + 'testimonials/'
export const SERVICE_TESTIMONIALS_API = API_URL + 'serviceTestimonials/'
export const SERVICES_API = API_URL + 'services/'
export const SUCCESS_STORIES_API = API_URL + 'successStories/'
export const FEATURED_SUCCESS_STORIES_API = API_URL + 'featuredSuccessStories/'
export const BLOGS_API = API_URL + 'blogs/'
export const ARTICLES_API = API_URL + 'articles/'
export const FEATURED_ARTICLES_API = API_URL + 'featuredArticles/'
export const EXHIBITIONS_API = API_URL + 'exhibitions/'
export const EXPO_WRITE_UPS_API = API_URL + 'expoWriteUps/'
export const PROGRAMS_API = API_URL + 'programs/'
export const EVENTS_API = API_URL + 'events/'
export const SUB_SERVICES_API = API_URL + 'subServices/'
export const IMPACTS_API = API_URL + 'impacts/'
export const SOCIAL_LINKS_API = API_URL + 'socialLinks/'
export const FEATURED_BLOGS_API = API_URL + 'featuredBlogs/'
export const CONTACTS_API = API_URL + 'contacts/'
export const CONTACT_INFOS_API = API_URL + 'contactInfos/'
export const FOOTER_TEXT_API = API_URL + 'footerTexts/'

//tags
export const IMAGE_GALLERY_TAGS_API = API_URL + 'imageGalleryTags/'
export const ARTICLE_TAGS_API = API_URL + 'articleTags/'
export const SUCCESS_STORY_TAGS_API = API_URL + 'successStoryTags/'

export const IMAGE_GALLERIES_API = API_URL + 'imageGalleries/'

