import coverImage from "../../../../Assets/Images/aboutPage/Cover.png";
import articleImage from "../../../../Assets/Images/aboutPage/Article.png";
import "./Magazine.css"
const Magazine = () => {
  return (
    <div className="container_fluid container py_10">
      <div className="magazineParent">
          <img src={coverImage} alt="coverImage" />
          <img src={articleImage} alt="articleImage" />
      </div>
    </div>
  );
};

export default Magazine;
