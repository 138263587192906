import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination, Autoplay } from 'swiper';
import axios from "axios";
import { IMAGE_URL, MAGAZINES_SLIDERS_API } from "../../../../Utilities/APIs";
import LargeBtn from "../../../Layouts/LargeBtn/LargeBtn";

import 'swiper/css';
import 'swiper/css/pagination';
import '../../../Layouts/OverflowSc/OverflowSc.css'

function Magazine() {

    const [magazines, setMagazines] = useState(null);

    useEffect(() => {
        async function getMagazines() {
            const { data } = await axios.get(MAGAZINES_SLIDERS_API);
            setMagazines(data);
        }

        getMagazines()
    }, [])


    return (
        <section className="overflow_sc my_10">
            <div className='height_only'></div>
            <div className="bg_black content pb_8">
                <div className="container_fluid container">
                    <Swiper
                        speed={1500}
                        spaceBetween={0}
                        slidesPerView={1}
                        effect={'fade'}
                        autoplay={{
                            delay: 2500
                        }}
                        // autoplay={false}
                        pagination={{
                            el: '.overflow_sc .swiper-pagination',
                            clickable: true,
                        }}
                        modules={[EffectFade, Pagination, Autoplay]}
                    >
                        {magazines &&
                            magazines?.map(magazine => (
                                <SwiperSlide key={magazine._id}>
                                    <div className="grid_container">
                                        <div className="grid_item">
                                            <div className="img_box">
                                                <img src={IMAGE_URL + magazine.image} alt="thumb" />
                                            </div>
                                        </div>
                                        <div className="grid_item">
                                            <h1 className='title_48 fc_white'>{magazine.name}</h1>
                                            <p className='text fc_white'>{magazine.description}</p>
                                            <div className="img_box">
                                                <img src={IMAGE_URL + magazine.image} alt="thumb" />
                                            </div>
                                            <LargeBtn
                                                link={`/`}
                                                thinText={magazine.buttonText1}
                                                boldText={magazine.buttonText2}
                                                isExternal
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    )
}

export default Magazine