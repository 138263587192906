import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { EXPO_WRITE_UPS_API, SERVICES_API } from "../../../Utilities/APIs";

import Navbar from "../../Partials/Navbar/Navbar";
import Banner from "./Banner/Banner";
import TitleDescImg from "./TitleDescImg/TitleDescImg";
import SrvVideo from "./SrvVideo/SrvVideo";
import SvrOverview from "./SvrOverview/SvrOverview";
import SrvImpacts from "./SrvImpacts/SrvImpacts";
import Events from "./Events/Events";
// import OverflowSc from '../../Layouts/OverflowSc/OverflowSc'
import PressReleaseSc from "../../Layouts/PressReleaseSc/PressReleaseSc";
import SuccessStory from "../../Layouts/SuccessStory/SuccessStory";
import Testimonial2 from "../../Layouts/Testimonial2/Testimonial2";
import Programs from "./Programs/Programs";
import Exhibitions from "./Exhibitions/Exhibitions";
import BlogsSc from "../../Layouts/BlogsSc/BlogsSc";
import Magazine from "./Magazine/Magazine";

import "./Service.css";
import ImageGallery from "../../Layouts/ImageGallery/ImageGallery";
import galleryImages from "../../../Utilities/galleryImages";

function Service() {
  const { slug } = useParams();

  const [showIframe, setShowIframe] = useState(false);
  const [addVLink, setAddVLink] = useState(false);
  const [itemID, setItemID] = useState("");
  const [singleService, setSingleService] = useState({});
  const [expoWriteUps, setExpoWriteUps] = useState(null);

  useEffect(() => {
    async function getSingleServices() {
      const { data } = await axios.get(
        SERVICES_API + "getSingleServiceForConsumer/" + slug
      );
  
      if (data.slug !== "uddinfoundation") {
        setSingleService(data);
      }
    }
  
    getSingleServices();
  }, [slug]);
  

  useEffect(() => {
    async function getxpoWriteUps() {
      const { data } = await axios.get(EXPO_WRITE_UPS_API);
      setExpoWriteUps(data[0]);
    }

    getxpoWriteUps();
  }, []);

  const openIframe = (link, ID) => {
    setShowIframe(true);
    setAddVLink(link);
    setItemID(ID);
  };
  const closeIframe = () => {
    setShowIframe(false);
    setAddVLink("");
    setItemID("");
  };

  return (
    <>
      <Navbar isTransparent />
      <main className="service_page">
        <Banner
          bgImg={singleService && singleService?.image}
          name={singleService && singleService?.name}
          precedence={singleService && singleService?.precedence}
        />

        <TitleDescImg
          title={singleService && singleService?.title}
          desc={singleService && singleService?.description}
        />

        <ImageGallery slug={slug} images={galleryImages[slug] || []} />

        <SvrOverview slug={slug} scTtitle={singleService?.subServiceTitle} />

        <SrvVideo
          thumb={singleService && singleService?.videoThumbnail}
          videoLink={singleService && singleService?.videoLink}
          parentId={slug}
          showIframe={showIframe}
          addVLink={addVLink}
          openIframe={openIframe}
          closeIframe={closeIframe}
          itemID={itemID}
        />
        {singleService && singleService?.isProgram && (
          <Programs slug={slug} scTtitle={singleService?.programTitle} />
        )}

        {singleService && singleService?.isImpact && (
          <SrvImpacts slug={slug} scTtitle={singleService?.impectTitle} />
        )}

        {singleService &&
          singleService?.name === "Expo Coaching" &&
          expoWriteUps?.isActive === true && (
            <TitleDescImg
              title={expoWriteUps?.title}
              desc={expoWriteUps?.description}
              imgSrc={expoWriteUps?.image}
            />
          )}

        {singleService && singleService?.name !== "Expo Coaching" && (
          <Testimonial2 isBlackBg slug={slug} />
        )}

        {singleService && singleService?.isBlog && (
          <BlogsSc
            slug={slug}
            scSubTitle={singleService?.blogSubTitle}
            scTtitle={singleService?.blogTitle}
          />
        )}

        {singleService && singleService?.isEvent && (
          <Events slug={slug} scTtitle={singleService?.eventTitle} />
        )}

        {singleService && singleService?.isExhibition && (
          <Exhibitions slug={slug} scTtitle={singleService?.exhibitionTitle} />
        )}

        {singleService && singleService?.isArticle && (
          <PressReleaseSc
            slug={slug}
            scSubTitle={singleService?.articleSubTitle}
            scTtitle={singleService?.articleTitle}
          />
        )}

        {singleService && singleService?.isMagazine && (
          // <OverflowSc
          //     img={storyImg}
          //     title="Bangladesh Denim Times"
          //     desc="Bangladesh Denim Times is a free and non-profit magazine. Proudly made and printed in Bangladesh."
          //     link="/"
          //     thinText="Download"
          //     boldText="Get Magazine"
          // />
          <Magazine />
        )}

        {singleService && singleService?.isSuccessStory && (
          <SuccessStory
            slug={slug}
            scSubTitle={singleService?.successStorySubTitle}
            scTtitle={singleService?.successStoryTitle}
          />
        )}

        {singleService && singleService?.name === "Expo Coaching" && (
          <Testimonial2 slug={slug} />
        )}
      </main>
    </>
  );
}

export default Service;
