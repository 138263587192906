import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AppContext from '../../../../AppContext/AppContext';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TESTIMONIALS_API } from '../../../../Utilities/APIs';
import TestmCard from './TestmCard/TestmCard';

import 'swiper/css';
import 'swiper/css/navigation';
import './Testimonials.css'

function Testimonials() {

    const { secTitles } = useContext(AppContext)

    const [testimonials, setTestimonials] = useState(null);

    useEffect(() => {
        async function getTestimonials() {
            const { data } = await axios.get(TESTIMONIALS_API);
            setTestimonials(data);
        }
        getTestimonials()
    }, [])

    return (
        <section className="testimonial_sc white_pagination py_10">
            <div className="container_fluid container">
                <div className="sch_wrapper with_flex right_bottom mb_5">
                    <div className="flex_item">
                        <h4 className="title_24">{secTitles?.testimonialSectionSubtitle}</h4>
                        <h1 className="title_60">{secTitles?.testimonialSectiontitle}</h1>
                    </div>
                    <div className="flex_item">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={16}
                    slidesPerView={1.25}
                    navigation={{
                        prevEl: `.testimonial_sc .swiper-button-prev`,
                        nextEl: `.testimonial_sc .swiper-button-next`
                    }}
                    pagination={{
                        el: '.testimonial_sc .swiper-pagination',
                        clickable: true,
                    }}

                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }}
                >
                    {testimonials?.map(testimonial => (
                        <SwiperSlide key={testimonial._id}>
                            <TestmCard testimonial={testimonial} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="swiper-pagination"></div>
        </section>
    )
}

export default Testimonials