import './Number.css'

function Number({text}) {
  return (
    <div className='srv_number'>
        <div className="inner">
            <h5>0{text}</h5>
        </div>
        <div className="line top"></div>
        <div className="line right"></div>
        <div className="line bottom"></div>
        <div className="line left"></div>
    </div>
  )
}

export default Number