import { IMAGE_URL } from "../../../../../Utilities/APIs";

function ImageGalleryCard({ imageGallery }) {
  return (
    <div className="g_image" key={imageGallery._id}>
      <img src={IMAGE_URL + imageGallery.image} alt="" />
    </div>
  );
}

export default ImageGalleryCard;
