import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/css';
import './HTSlider.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ABOUT_CHANGETEXT_API } from '../../../../Utilities/APIs';

function HTSlider() {

    const [changeText, setChangeText] = useState(null)

    useEffect(() => {
        async function getChangeText() {
            const { data } = await axios.get(ABOUT_CHANGETEXT_API);
            setChangeText(data);
        }
        getChangeText()
    }, [])

    return (
        <div className='highlighted_text_slider'>
            <Swiper
                direction={'vertical'}
                loop={true}
                // speed={2000}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{
                    delay: 1000
                }}
                modules={[Autoplay]}
            >
                {changeText?.map(item => (
                    <SwiperSlide key={item._id}>
                        <h1 className="abt_page_title chnage_text"><span>{item?.name}</span></h1>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default HTSlider