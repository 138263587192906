import { useEffect, useState } from 'react';
import axios from 'axios';
import { ABOUTS_API, IMAGE_URL } from '../../../../Utilities/APIs';
import LargeBtn from '../../../Layouts/LargeBtn/LargeBtn'
// import imgAbt from '../../../../Assets/Images/home_about-img.png'

import './HomeAbout.css'

function HomeAbout() {
    const [homeAbouts, setHomeAbouts] = useState(null);

    useEffect(() => {
        async function getWorkFeatures() {
            const { data } = await axios.get(ABOUTS_API);
            setHomeAbouts(data);
        }
        getWorkFeatures()
    }, [])


    return (
        <section className="home_about py_10">
            <div className="container_fluid container">
                <div className="grid_container">
                    <div className="grid_item">
                        <h4 className='title_24'>{homeAbouts && homeAbouts[0]?.subtitle}</h4>
                        <h1 className='title_60'>{homeAbouts && homeAbouts[0]?.title}</h1>
                        <div className="img_box">
                            <img src={homeAbouts && IMAGE_URL + homeAbouts[0]?.image} alt="thumb" />
                        </div>
                        <p className='mb_4 text'>{homeAbouts && homeAbouts[0]?.description}</p>
                        <LargeBtn
                            link='/about'
                            thinText={homeAbouts && homeAbouts[0]?.buttonText1}
                            boldText={homeAbouts && homeAbouts[0]?.buttonText2}
                        />
                    </div>
                    <div className="grid_item">
                        <div className="img_box">
                            <img src={homeAbouts && IMAGE_URL + homeAbouts[0]?.image} alt="thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeAbout