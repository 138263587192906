import playIcon from '../../../../Assets/Icons/video-play-white.svg'
import { IMAGE_URL } from '../../../../Utilities/APIs'
import Iframe from '../../../Layouts/Iframe/Iframe'

import './SrvVideo.css'

function SrvVideo({ thumb, videoLink, parentId, showIframe, addVLink, openIframe, closeIframe, itemID }) {


  return (
    <section className="srv_video_sc py_10">
      <div className="container_fluid container">
        <div className="video">
          <img src={IMAGE_URL + thumb} alt="thumb" />
          {videoLink !== "" &&
            <button type='button' className='play_btn' onClick={() => openIframe(videoLink, parentId)}>
              <img src={playIcon} alt="icon" />
            </button>
          }
          {showIframe && (itemID === parentId) &&
            <Iframe
              videoLink={addVLink}
              closeFunc={closeIframe}
            />
          }
        </div>
      </div>
    </section>
  )
}

export default SrvVideo