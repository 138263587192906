import "./ImageGallery.css";
import { useEffect, useState } from "react";
import {
  IMAGE_GALLERIES_API,
  IMAGE_GALLERY_TAGS_API
} from "../../../../Utilities/APIs";
import axios from "axios";
import MoreButton from "../../NewsMedia/MoreButton/MoreButton";
import Tags from "../../NewsMedia/Tags/Tags";
import SelectTags from "../../NewsMedia/SelectTags/SelectTags";
import ImageGalleryCard from "./ImageGalleryCard/ImageGalleryCard";

function ImageGallery() {
  const [selectedTag, setSelectedTag] = useState("all");

  const [showImages, setShowImages] = useState(12);
  const [showMobileImages, setShowMobileImages] = useState(3);

  const [imageGalleryTags, setImageGalleryTags] = useState(null);
  const [imageGalleries, setImageGalleries] = useState(null);

  const filterDatas = imageGalleries?.filter((imageGallery) =>selectedTag === "all" || imageGallery?.imageGalleryTag === selectedTag)

  useEffect(() => {
    async function getImageGalleryTags() {
      const { data } = await axios.get(IMAGE_GALLERY_TAGS_API);
      setImageGalleryTags(data);
    }
    getImageGalleryTags();
  }, []);

  useEffect(() => {
    async function getImageGalleries() {
      const { data } = await axios.get(IMAGE_GALLERIES_API);
      setImageGalleries(data);
    }
    getImageGalleries();
  }, []);

  const handleClick = (tagId) => {
    setSelectedTag(tagId);
  };

  const handleLoadMore = () => {
    setShowImages((prevCount) => prevCount + 6);
  };

  const handleClickForSmall = () => {
    setShowMobileImages((prevCount) => prevCount + 3);
  };
  return (
    <div className="image_gallery ">
      <div className="container_fluid container">
        <h1>Gallery</h1>
        <Tags
          selectedTag={selectedTag}
          handleClick={handleClick}
          allText="All Photos"
          tags={imageGalleryTags}
        />

        <SelectTags
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          allText="All Photos"
          tags={imageGalleryTags}
        />
        <div className="g_images">
          {filterDatas?.slice(0, showImages)?.map((imageGallery) => (
              <ImageGalleryCard imageGallery={imageGallery} />
            ))}
        </div>

        <div className="g_images_small">
          {
            filterDatas?.slice(0, showMobileImages)?.map((imageGallery) => (
              <ImageGalleryCard imageGallery={imageGallery} />
            ))}
        </div>
        {
          filterDatas?.length > showImages &&
          <MoreButton handleClick={handleLoadMore} text=' Load More' isLarge/>
        }
        {
          filterDatas?.length > showMobileImages &&
          <MoreButton handleClickForSmall={handleClickForSmall} text=' Load More' isSmall/>
        }
      </div>
    </div>
  );
}

export default ImageGallery;
